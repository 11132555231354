import React, { useContext } from 'react';
import styled from 'styled-components';
import { H3, P, breakpoints } from 'styles';
import { LocalContext } from 'context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';

const EventSponsor = ({ eventSponsor }) => {
  const { theme } = useContext(LocalContext);
  return (
    <SectionWrapper $theme={theme} id="info">
      <Title $theme={theme}>Our sponsor</Title>
      <Content fullWidth={!eventSponsor.video}>
        <Description fullWidth={!eventSponsor.video}>
          <GatsbyImage image={getImage(eventSponsor.image)} />
          <P>
            <Markup content={eventSponsor.description} />
          </P>
        </Description>
        {eventSponsor.video && (
          <VideoWrapper $theme={theme}>
            <iframe
              title="Sample"
              src={eventSponsor.video}
              frameBorder="0"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
            />
          </VideoWrapper>
        )}
      </Content>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: ${({ $theme }) => $theme.bg};
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.textMuted};
    font-family: 'Open Sans', sans-serif;
  }
  ${breakpoints.lg} {
    padding: 6.25rem 9rem;
  }
`;

const Title = styled(H3)`
  color: ${({ $theme }) => $theme.fg};
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${({ fullWidth }) => (fullWidth ? '600px' : '100%')};
  row-gap: 1.25rem;
  ${breakpoints.lg} {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 1fr')};
  }
`;

const VideoWrapper = styled.div`
  border: 3px solid #a58a4b;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  .gatsby-image-wrapper {
    height: auto;
    width: 100%;
    max-width: 314px;
    max-height: 216px;
    margin-bottom: 1.5rem;
    img {
      object-fit: contain !important;
      object-position: left bottom;
    }
  }
  ${breakpoints.sm} {
    align-items: center;
  }
  ${breakpoints.lg} {
    align-items: ${({ fullWidth }) => (fullWidth ? 'center' : 'start')};
  }
`;

export default EventSponsor;
